import create from 'zustand';
import ax from '../lib/api';

const useZipCodeStore = create((set, get) => ({
  counties: [],
  isLoading: false,
  error: null,
  selectedZipCodes: new Set(),

  fetchCounties: async () => {
    set({ isLoading: true, error: null });
    try {
      const response = await ax.get('/api/users/provider/zip-codes/');
      const counties = response.data;

      const selectedZipCodes = new Set();
      counties.forEach((county) => {
        county.zipCodes.forEach((zipCode) => {
          if (zipCode.isLinked) {
            selectedZipCodes.add(zipCode.id);
          }
        });
      });

      set({
        counties,
        selectedZipCodes,
        isLoading: false,
      });
    } catch (error) {
      console.error('Fetch error:', error);
      set({
        error: error.response?.data?.error || 'Failed to fetch counties',
        isLoading: false,
      });
    }
  },

  toggleZipCode: (zipCodeId) => {
    set((state) => {
      const newSelectedZipCodes = new Set(state.selectedZipCodes);
      if (newSelectedZipCodes.has(zipCodeId)) {
        newSelectedZipCodes.delete(zipCodeId);
      } else {
        newSelectedZipCodes.add(zipCodeId);
      }
      return { selectedZipCodes: newSelectedZipCodes };
    });
  },

  toggleAllCountyZipCodes: (countyId, zipCodeIds, shouldSelect) => {
    set((state) => {
      const newSelectedZipCodes = new Set(state.selectedZipCodes);
      zipCodeIds.forEach((zipCodeId) => {
        if (shouldSelect) {
          newSelectedZipCodes.add(zipCodeId);
        } else {
          newSelectedZipCodes.delete(zipCodeId);
        }
      });
      return { selectedZipCodes: newSelectedZipCodes };
    });
  },

  saveZipCodes: async () => {
    set({ isLoading: true, error: null });
    try {
      const zipCodeIds = Array.from(get().selectedZipCodes);
      console.log('Sending zip codes:', zipCodeIds); // Debug log

      const response = await ax.patch('/api/users/provider/zip-codes/', {
        zipCodeIds,
      });

      console.log('Save response:', response); // Debug log
      set({ isLoading: false });
      return true;
    } catch (error) {
      console.error('Save error:', error.response?.data || error); // Enhanced error logging
      set({
        error: error.response?.data?.error || 'Failed to save zip codes',
        isLoading: false,
      });
      throw error; // Propagate error to component
    }
  },
}));

export default useZipCodeStore;
