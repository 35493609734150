import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import FlexBox from '../layout/FlexBox';
import ax from '../../lib/api';
import LoadingButton from '../forms/LoadingButton';
import PlainInput from '../forms/PlainInput';
import PlainInputLabel from '../forms/PlainInputLabel';

const ProviderDocsForm = ({ user }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [documents, setDocuments] = useState([]);

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);

    const validFiles = files.filter((file) => {
      const validTypes = [
        'application/pdf',
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/bmp',
        'image/gif',
      ];
      const maxSize = 50 * 1024 * 1024; // 50 MB

      if (!validTypes.includes(file.type)) {
        setError(
          `Invalid file type: ${file.name}. Only PNG, JPEG, BMP, GIF, JPG, and PDF files are allowed.`
        );
        return false;
      }
      if (file.size > maxSize) {
        setError(
          `File size too large: ${file.name}. Files must be less than 50 MB.`
        );
        return false;
      }
      if (file.name.length >= 100) {
        setError(
          `File name too long. File names must be less than 100 characters.`
        );
        return false;
      }
      return true;
    });

    if (validFiles.length > 0) {
      setDocuments((prev) => [...prev, ...validFiles]);
      setError('');
    }
  };

  const handleRemoveDocument = (document) => {
    setDocuments((prev) => prev.filter((doc) => doc !== document));
  };

  const handleSubmit = async () => {
    if (documents.length === 0) {
      setError('No documents to upload.');
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();

      // Append each document to FormData
      documents.forEach((document) => {
        formData.append('files', document, document.name);
      });

      const res = await ax.post(`/api/users/upload-docs/`, formData);

      if (res.status === 201) {
        alert('Documentation updated successfully.');
        setDocuments([]);
        setError('');
      }
    } catch (e) {
      setError('There was an error uploading the documentation.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box width="100%">
      <Typography variant="body2" color="textSecondary" mb={4}>
        To update your services/counties, please upload a new version of your
        documentation from Indiana FSSA/OMPP and click "Save".
      </Typography>

      <Box mb={4}>
        <Box mb={2}>
          <PlainInputLabel>Document</PlainInputLabel>
        </Box>

        <input
          accept=".pdf,.png,.jpg,.jpeg,.bmp,.gif"
          style={{ display: 'none' }}
          id="document-upload"
          type="file"
          multiple
          onChange={handleFileSelect}
        />
        <FlexBox direction="column" spacing={2}>
          <label htmlFor="document-upload">
            <LoadingButton component="span" variant="outlined" color="primary">
              Browse...
            </LoadingButton>
          </label>

          {documents.length > 0 && (
            <Box mt={2} ml={4}>
              {' '}
              {/* Added margin-left to indent the document list */}
              {documents.map((doc, index) => (
                <FlexBox key={index} alignItems="center" mb={1}>
                  <Typography
                    variant="body2"
                    style={{
                      marginRight: theme.spacing(2),
                      color: theme.palette.primary.main, // Match the blue color scheme
                    }}
                  >
                    {doc.name}
                  </Typography>
                  <LoadingButton
                    variant="text"
                    color="primary"
                    onClick={() => handleRemoveDocument(doc)}
                  >
                    Remove
                  </LoadingButton>
                </FlexBox>
              ))}
            </Box>
          )}
        </FlexBox>
      </Box>

      {error && (
        <Typography variant="caption" color="error" display="block" mb={2}>
          {error}
        </Typography>
      )}

      <Typography
        variant="caption"
        color="textSecondary"
        display="block"
        mb={3}
      >
        Only PDF and image files (png, jpg, jpeg, bmp, gif) are accepted.
        Maximum file size is 50 MB.
      </Typography>

      <FlexBox>
        <LoadingButton
          variant="text"
          color="primary"
          onClick={() => {
            setDocuments([]);
            setError('');
          }}
          style={{ marginRight: theme.spacing(2) }}
        >
          Cancel
        </LoadingButton>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={documents.length === 0}
          onClick={handleSubmit}
        >
          Save
        </LoadingButton>
      </FlexBox>
    </Box>
  );
};

ProviderDocsForm.propTypes = {
  user: PropTypes.object.isRequired,
};

export default ProviderDocsForm;
